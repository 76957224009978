import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import fallbackIcon from '@atlas/core-atlas-vpn/flags/fallback_flag.svg';

import { Box } from '@core';
import { ImageCore } from '@components/image';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const AnimatedImage = styled(ImageCore)`
  animation-name: ${fadeIn};
  animation-duration: 500ms;
`;

const FlagComponent = ({ iso, ...props }) => {
  const [flag, setFlag] = useState(fallbackIcon);
  import(`@atlas/core-atlas-vpn/flags/${iso.toLowerCase()}_flag.svg`)
    .then(({ default: icon }) => {
      setFlag(icon);
    })
    .catch(() => {
      console.error(
        new Error(
          `Flag @atlas/core-atlas-vpn/flags/${iso.toLowerCase()}_flag.svg can not be fetched`
        )
      );
    });

  return (
    <Box display="inline-flex" width={62} position="relative" {...props}>
      <AnimatedImage src={flag} alt={`${iso} flag`} />
    </Box>
  );
};

export default FlagComponent;
